var render = function () {
  var _vm$item$cas_organiza, _this$item, _this$item$sync_vendo, _this$item2, _this$item2$sync_vend, _this$item3, _this$item3$sync_vend, _this$item4, _this$item4$sync_vend, _vm$itemVendor;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    staticClass: "p-2 mt-2"
  }, [_c('h2', {
    staticClass: "text-primary"
  }, [_vm._v("Vendor Detail")]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Vendor Name")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.item.name) + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Slug")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.item.slug) + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Organization Name")]), _c('b-col', [_vm._v(" : " + _vm._s((_vm$item$cas_organiza = _vm.item.cas_organization) === null || _vm$item$cas_organiza === void 0 ? void 0 : _vm$item$cas_organiza.name) + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Description")]), _c('b-col', {
    attrs: {
      "sm": "10"
    }
  }, [_vm._v(" : " + _vm._s(_vm.item.description == null || _vm.item.description == '' ? '-' : _vm.item.description) + " ")])], 1), ((_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$sync_vendo = _this$item.sync_vendor) === null || _this$item$sync_vendo === void 0 ? void 0 : _this$item$sync_vendo.vendor_premium_id_ax) !== '-1' ? _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("New Premium Vendor Item ID")]), _c('b-col', {
    attrs: {
      "sm": "10"
    }
  }, [_vm._v(" : "), ((_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$sync_vend = _this$item2.sync_vendor) === null || _this$item2$sync_vend === void 0 ? void 0 : _this$item2$sync_vend.vendor_premium_id_ax) === '0' ? _c('b-button', {
    staticClass: "px-1 py-0",
    attrs: {
      "sm": "2"
    },
    on: {
      "click": function ($event) {
        return _vm.requestPremiumId();
      }
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.requestBtnLoading ? 'Loading ...' : 'Request Id'))])]) : ((_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : (_this$item3$sync_vend = _this$item3.sync_vendor) === null || _this$item3$sync_vend === void 0 ? void 0 : _this$item3$sync_vend.vendor_premium_id_ax) === '1' ? _c('span', {
    staticStyle: {
      "color": "#ffc107"
    }
  }, [_vm._v("In Prosess")]) : _c('span', [_vm._v(" " + _vm._s((_this$item4 = this.item) === null || _this$item4 === void 0 ? void 0 : (_this$item4$sync_vend = _this$item4.sync_vendor) === null || _this$item4$sync_vend === void 0 ? void 0 : _this$item4$sync_vend.vendor_premium_id_ax))])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Vendor Type")]), _vm.item.is_external ? _c('b-col', {
    attrs: {
      "sm": "10"
    }
  }, [_vm._v(" : "), _c('span', [_vm._v("External")])]) : _c('b-col', {
    attrs: {
      "sm": "2"
    }
  }, [_vm._v(" : "), _c('span', [_vm._v("Internal")])])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Meta")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.item.meta == null || _vm.item.meta == '' ? '-' : _vm.item.meta) + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Is Active")]), _vm.item.is_active ? _c('b-col', {
    attrs: {
      "sm": "10"
    }
  }, [_vm._v(" : "), _c('span', {
    staticClass: "badge badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" active")])]) : _c('b-col', {
    attrs: {
      "sm": "2"
    }
  }, [_vm._v(" : "), _c('span', {
    staticClass: "badge badge-danger"
  }, [_c('i', {
    staticClass: "fa fa-close"
  }), _vm._v(" inactive")])])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Status")]), _c('b-col', [_vm._v(" : "), _c('VendorStatus', {
    attrs: {
      "vendor_status": parseInt(_vm.item.vendor_status)
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Display Content")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.item.display_content == null || _vm.item.display_content == '' ? '-' : _vm.item.display_content) + " ")])], 1), !_vm.previewImage ? _c('b-row', {
    staticClass: "mb-1 mt-4"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "8"
    }
  }, [_vm.item.icon_image_normal !== null ? _c('img', {
    staticClass: "form-control-file",
    staticStyle: {
      "width": "20%",
      "border": "1px solid #ccc"
    },
    attrs: {
      "src": _vm.imageSrc,
      "alt": "Vendor Image"
    },
    on: {
      "error": function ($event) {
        return _vm.handleImageError($event);
      }
    }
  }) : _c('img', {
    staticClass: "form-control-file",
    staticStyle: {
      "width": "20%",
      "border": "1px solid #ccc"
    },
    attrs: {
      "src": _vm.imagePath,
      "alt": "Vendor Image"
    }
  })])], 1) : _vm._e(), _vm.previewImage ? _c('div', [!_vm.item.icon_image_normal ? _c('div', {
    staticClass: "form-group mt-3",
    staticStyle: {
      "width": "20%",
      "overflow": "hidden"
    }
  }, [_c('img', {
    staticClass: "form-control-file",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.previewImage,
      "alt": "Vendor Image"
    }
  })]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "parent-wapper-image"
  }, [!_vm.item.icon_image_normal ? _c('div', {
    staticClass: "upload-btn-wrapper"
  }, [_c('button', {
    staticClass: "btn btn-warning mt-2",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" " + _vm._s(this.previewImage !== null ? 'Change Image Vendor' : 'Upload Image Vendor') + " ")]), _c('input', {
    ref: "file",
    staticClass: "form-control-file",
    attrs: {
      "type": "file",
      "name": "file",
      "id": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.onChangeData($event);
      }
    }
  })]) : _vm._e(), _vm.previewImage !== null ? _c('button', {
    staticClass: "btn btn-warning mt-3",
    staticStyle: {
      "width": "13.2%"
    },
    on: {
      "click": function ($event) {
        return _vm.updateImageVendor();
      }
    }
  }, [_vm._v(" Save ")]) : _vm._e()])], 1)]), _c('hr'), _c('h2', {
    staticClass: "text-primary"
  }, [_vm._v("Aggrement")]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Vendor ID AX")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.item.sync_vendor ? _vm.item.sync_vendor.vendor_id_ax : '-') + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Default Fixed Amount")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.itemRevenue ? _vm.itemRevenue.default_fixed_amount : '-') + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Default Percentage Amount")]), _c('b-col', [_vm._v(" : "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.itemRevenue ? ((_vm.toPercent(_vm.itemRevenue.default_percentage_amount)) + " %") : '-'))])])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Formula Name")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.itemRevenue ? _vm.itemRevenue.formula_name : '-') + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Formula ID")]), _c('b-col', [_vm._v(" : " + _vm._s(_vm.itemRevenue ? _vm.itemRevenue.formula_id : '-') + " ")])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "sm": "2"
    }
  }, [_vm._v("Payment Currency")]), _c('b-col', [_vm._v(" : "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.itemVendor ? (_vm$itemVendor = _vm.itemVendor) === null || _vm$itemVendor === void 0 ? void 0 : _vm$itemVendor.payment_currency : '-'))])])], 1)], 1)]), _c('hr'), _c('h2', {
    staticClass: "text-primary"
  }, [_vm._v("Aggregation")]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 rounded mb-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.content.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', [_c('ChartContent', {
    attrs: {
      "title": 'Content',
      "series": _vm.seriesContent,
      "options": _vm.optionsContent
    }
  })], 1)], 1) : _vm._e(), _vm.content ? _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "show-empty": "",
      "striped": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.content
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(count)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPriceBasic(row.item.count)) + " ")];
      }
    }], null, false, 1214861228)
  })], 1)], 1) : _vm._e()], 1)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }